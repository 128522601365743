.weekly-calendar {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.weekly-calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures all columns have equal width */
}

.weekly-calendar th,
.weekly-calendar td {
  text-align: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  overflow: hidden; /* Prevent content overflow */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  white-space: nowrap; /* Keeps text in a single line */
}

.weekly-calendar th {
  background-color: #f4f6f9;
  font-weight: bold;
}

.user-name {
  background-color: #f4f6f9;
  font-weight: bold;
  text-align: left;
  padding-left: 15px;
}

.day-cell {
  height: 30px;
  vertical-align: top;
  position: relative;
  overflow: hidden; /* Ensures that long content doesn't overflow the cell */
  padding: 5px; /* Add padding to ensure spacing around the event */
}

/* Event styling */
.event-blue {
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
  font-size: 12px;
  line-height: 1.4;
  text-align: left;
  cursor: pointer;
  width: 100%; /* Make the event take up the full column width */
  box-sizing: border-box; /* Includes padding in the width calculation */
}

.event-blue:hover {
  background-color: #0056b3;
  color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/* No event placeholder */
.no-event {
  cursor: pointer;
  color: #888;
  font-size: 14px;
  line-height: normal; /* Reset to normal line-height */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  height: 117%; /* Ensure it spans the full height of the cell */
}

/* No event placeholder */
.add-event {
  cursor: pointer;
  color: #888;
  font-size: 14px;
  line-height: normal; /* Reset to normal line-height */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  /*height: 106%; /* Ensure it spans the full height of the cell */
}

/* Responsive styles */
@media (max-width: 768px) {
  .weekly-calendar th,
  .weekly-calendar td {
    font-size: 12px;
    padding: 8px;
  }

  .event-blue {
    font-size: 10px;
  }
}

/* Popover wrapper */
.ant-popover {
  max-width: 300px; /* Adjust the maximum width */
  font-family: Arial, sans-serif;
  font-size: 14px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Slight shadow for visibility */
}

/* Popover title */
.ant-popover-inner-title {
  font-weight: bold;
  font-size: 16px;
  color: #333; /* Darker color for the title */
  margin-bottom: 5px; /* Space below the title */
}

/* Popover content body */
.ant-popover-content {
  color: #555; /* Neutral text color */
  line-height: 1.5; /* Improve readability */
}

/* Hover effect for better focus 
.ant-popover:hover {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3); 
}*/

/* Arrow container */
.ant-popover-arrow {
  width: 14px;
  height: 14px;
  transform: rotate(45deg); /* Keeps the diamond shape */
  margin: -7px; /* Proper alignment */
  z-index: 1; /* Ensure the arrow stays above other elements */
  background: none;
}

/* Arrow content */
.ant-popover-arrow-content {
  background-color: #ffffff; /* Match the popover's white background */
  border: 1px solid #e0e0e0; /* Consistent border */
}

/* Popover box */
.ant-popover-inner {
  border: 1px solid #e0e0e0; /* Match arrow border */
  background-color: #ffffff; /* Ensure consistent white background */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  border-radius: 4px; /* Optional for modern appearance */
  padding: 12px; /* Proper spacing for content */
  position: relative;
}

/* Remove the transparent area around the Popover */
.ant-popover {
  background-color: transparent; /* Eliminate default semi-transparent behavior */
  box-shadow: none; /* Ensure no extra shadows from container */
}

/* Remove any weird layering or extra effects */
.ant-popover-inner:after,
.ant-popover-inner:before {
  content: none; /* Removes any unintended pseudo-elements */
}

.overlapping-message {
  color: red;
  font-weight: bold;
  font-size: 18px; /* Adjust as needed */
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.status-message {
  color: darkslategray;
  font-weight: bold;
  font-size: 15px; /* Adjust as needed */
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
